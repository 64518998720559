r<!-- 定义模版对象 -->
<template>
  <div class="register">
    <div v-if="current_page == 2" class="register-users">
      <div class="register-users-title">
        嗨数
      </div>
      <div class="register-users-div">
        <el-divider style="background-color: #000 !important">{{ register_model.name || '' }}注册</el-divider>
      </div>
      <div class="register-users-warn">
        <el-alert :title="register_model.desc || ''" type="warning" effect="dark" :closable="false" center>
        </el-alert>
      </div>
      <div class="register-users-line">
        <div class="register-users-line-back">
          <el-button type="text" style="color: #4095e5;" @click="toBack">返回上一级</el-button>
        </div>
        <div class="register-users-line-login">
          <span>已有账号？</span><el-button type="text" style="color: #4095e5;" @click="toLogin">立即登陆</el-button>
        </div>
      </div>
      <div class="register-users-form">
        <el-card>
          <div class="register-users-form-main">
            <div class="register-users-form-main-left">
              <el-form ref="form" :model="register_form" :rules="rules" label-width="80px">
                <el-form-item label="手机号" prop="tel">
                  <el-input v-model="register_form.tel" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                  <el-input v-model="register_form.code" placeholder="请输入验证码">
                    <el-button type="primary" slot="append" style="color: #fff !important; background-color: #4095e5 !important;">获取验证码</el-button>
                  </el-input>
                </el-form-item>
                <el-form-item label="用户密码" prop="pas">
                  <el-input type="password" v-model="register_form.pas" show-password placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="confirm_pas">
                  <el-input type="password" v-model="register_form.confirm_pas" show-password placeholder="请再次输入密码"></el-input>
                </el-form-item>
                <el-form-item label="QQ号" prop="qq">
                  <el-input v-model="register_form.qq" placeholder="请输入QQ号"></el-input>
                </el-form-item>
                <el-form-item label="联系人" prop="link_man">
                  <el-input v-model="register_form.link_man" placeholder="请输入联系人"></el-input>
                </el-form-item>
                <el-form-item label=" ">
                  <el-checkbox v-model="register_form.agree">我已阅读并同意
                    <el-button type="text" style="color: #4095e5;" @click="toLogin">《嗨数网销卖家注册协议》</el-button>
                    <el-button type="text" style="color: #4095e5;" @click="toLogin">《嗨数隐私协议》</el-button></el-checkbox>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="onSubmit">确认注册并登录</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="register-users-form-main-right">
              <div class="register-users-list-item-icon" v-for="(item,index) in pics" :key="index">
                {{ item }}
              </div>
            </div>
          </div>

        </el-card>
      </div>
    </div>
    <!-- 使用人群页面 -->
    <div v-else class="register-users">
      <div class="register-users-title">
        嗨数
      </div>
      <div class="register-users-div">
        <el-divider style="background-color: #000 !important">请选择你要注册的类型</el-divider>
      </div>
      <div class="register-users-login">
        <span>已有账号？</span><el-button type="text" style="color: #4095e5;" @click="toLogin">立即登陆</el-button>
      </div>
      <div class="register-users-list">
        <div v-for="item in shop_types_list" :key="item.type" class="register-users-list-item">
          <el-row :gutter="20">
            <el-col :span="20">
              <div style="display: flex">

                <div class="register-users-list-item-icon">

                </div>
                <div class="register-users-list-item-main">
                  <div class="register-users-list-item-main-title">
                    <span>我要注册</span><span class="register-users-list-item-main-title-type">{{ item.name || '' }}</span>
                  </div>
                  <div class="register-users-list-item-main-desc">
                    {{ item.desc || '' }}
                  </div>
                </div>
              </div>

            </el-col>
            <el-col :span="4">
              <div class="register-users-list-item-right">
                <el-button type="primary" @click="toRegister(item)">点击注册</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- 定义JS变量 -->
<script>
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if(value === '') {
        callback(new Error('请再次输入密码'));
      } else if(value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      current_page: 1,
      register_model: {},
      register_form: {},
      pics: [1, 2, 3, 4, 5,6,7,8,9,10,11,12,13,14,15,1],
      shop_types_list: [
        {
          name: '供货厂家',
          desc: '女鞋生产企业以及经销供货、代理商统一注册类型为供货商家',
          type: 1,
          icon: '',
        },
        {
          name: '网销卖家',
          desc: '各类网销卖家、批发商、个人买家统一注册类型为网销卖家',
          type: 2,
          icon: '',
        }
      ],
      rules: {
        tel: [
          { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: ['blur', 'change'] }
        ],
        pas: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] }
        ],
        confirm_pas: [
          { required: true, message: '请再次输入密码', trigger: ['blur', 'change'] },
          { validator: validatePass2, trigger: 'blur' }
        ],
        qq: [
          { required: true, message: '请输入QQ号', trigger: ['blur', 'change'] }
        ],
        link_man: [
          { required: true, message: '请输入联系人', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  created() {

  },
  methods: {
    //返回上一级
    toBack(){
        this.register_form = {};
        this.register_model = {};
        this.$nextTick(()=>{
            this.current_page = 1;
        })
    },
    //立即注册
    toRegister(data) {
      console.log('data', data)
      this.register_model = Object.assign({}, { ...data });
      this.$nextTick(() => {
        this.current_page = 2;
      })
    },
    //去登录    
    toLogin() {
      this.$router.push({
        path: '/login',
        query: {
        }
      })
    }
  }

}
</script>

<!-- 防止组件冲突 -->
<style lang="less" scoped>
.register {
  height: 100vh;
  background: #f2f2f2;
  &-users {
    padding: 40px;
    &-title {
      text-align: center;
      font-size: 36px;
    }
    &-div {
      /deep/.el-divider {
        background: #000;
      }
      /deep/.el-divider__text {
        background: #f2f2f2;
      }
    }
    &-warn {
      position: relative;
      left: 30%;
      right: 30%;
      width: 40%;
      text-align: center;
    }
    &-line {
      display: flex;
      &-back {
        color: #4095e5;
        text-align: left;
        width: 50%;
      }
      &-login {
        color: #634949;
        text-align: right;
        width: 50%;
      }
    }
    &-form{
        &-main{
            display: flex;
            &-left{
                width: 50%;
            }
            &-right{
                width: 50%;
                padding: 0 30px;
                display: flex;
                flex-flow: wrap;
            }
        }
    }
    &-login {
      color: #634949;
      text-align: right;
    }
    &-list {
      margin-top: 20px;
      &-item {
        margin-bottom: 20px;
        &-icon {
          width: 100px;
          height: 100px;
          background-color: red;
          margin-right: 20px;
          border-radius: 10px;
          text-align: center;
          justify-content: center;
        }
        &-main {
          &-title {
            font-size: 24px;
            &-type {
              font-weight: bold;
            }
          }
          &-desc {
            font-size: 16px;
            color: #634949;
          }
        }
        &-right {
          text-align: right;
        }
      }
    }
  }
}
</style>
